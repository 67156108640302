// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // for running local emulators
    // host: 'localhost:5000',
    // ssl: false,
    // databaseURL: 'http://localhost:9000?ns=boxes-scheduler-default-rtdb',
    apiKey: "AIzaSyB-KAG3GxvVg4ORm9jtHzehmCFYe-9eJzk",
    authDomain: "boxes-scheduler.firebaseapp.com",
    projectId: "boxes-scheduler",
    storageBucket: "boxes-scheduler.appspot.com",
    messagingSenderId: "765811519049",
    appId: "1:765811519049:web:aee5821e5343b550409998"
  },
  release: 0.9
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
